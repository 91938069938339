.roles__role_container {
  background-color: #ede7e0;
  border: solid 1px #ede7e0;
  border-radius: 5px;
}

.roles__role_container.active,
.roles__role_container:hover {
  border: solid 1px #fc8f00 !important;
  background-color: white;
  cursor: pointer;
}

.roles__role_container.active {
  cursor: default;
}

.roles__role_active_text {
  color: #fc8f00;
}
