.candidate_role__thumbs_col {
  background-color: #ede7e0;
  font-size: 1rem;
  text-align: center;
}
.candidate_role__thumbs_col:hover {
  cursor: pointer;
  color: white;
}
.candidate_role__thumbs_col.up:hover {
  background-color: #28cca5;
}
.candidate_role__thumbs_col.down:hover {
  background-color: #f22800;
}
