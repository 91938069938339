.edit_modal__category {
  border: solid 1px #dfe6e9;
  background-color: #dfe6e9;
}

#category-name-addon {
  background-color: #a29bfe;
  color: white;
}

.path__modal_level_title {
  font-size: 0.8rem;
  font-weight: 600;
}

.path__modal_level_0 {
  color: #a29bfe;
}

.path__modal_level_1 {
  color: #6c5ce7;
}

.path__modal_level_2 {
  color: #fdcb6e;
}

.path__modal_level_3 {
  color: #00b894;
}
