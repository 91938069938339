.achievements_display__title_row {
  font-size: 1rem;
  font-weight: 600;
}

.achievements_display__subdimension_row {
  border: solid 1px #EDE7E0;
  font-size: 0.6rem;
}

.achievements_display__subdimension_row.active {
  background-color: #28CCA5;
  color: white;
}

.achievements_display__subdimension_row.rejected {
  background-color: #F22800;
  color: white;
}
