.path__direction_tag {
  border: solid 1px #005daa;
  border-radius: 5px;
}

.path__direction_tag.active,
.path__direction_tag:hover {
  background-color: #005daa;
  color: white;
}

.path__direction_tag:hover {
  cursor: pointer;
}
