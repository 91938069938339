.collapsed_col {
  overflow: hidden;
  filter: blur(4px);
}

.collapsed_col:hover {
  cursor: pointer;
  filter: none;
}

.smart {
  border: solid 1px #fc8f00;
  border-radius: 5px;
}
