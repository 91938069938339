.ril__navbar {
  background-color: white;
}

.ril__navbar_brand {
  cursor: pointer;
}

.nav__logout {
  cursor: pointer;
}
