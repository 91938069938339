.role_evaluation__card_title {
  background-color: #FC8F00;
  color: #FAFAFA;
  text-align: center;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.role_evaluation__card_body {
  padding-top: 0!important;
}

.role_evaluation__card_display_switch {
  border: solid 1px #005DAA;
  border-radius: 5px;
}

.role_evaluation__card_display_switch:hover {
  background-color: #005DAA;
  color: white;
  cursor: pointer;
}
