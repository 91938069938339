.path__level.row {
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-radius: 15px;
}

.path__level.row {
  background-color: #FC8F00;
}

.path__level_new:hover {
  background-color: #fd79a8 !important;
  color: white;
}

.path__level.row:hover * > .path__title {
  color: white;
}

.path__level.row:hover * > .path__price {
  color: white;
}

.path__level_collapsed {
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-radius: 15px;
  background-color: #FC8F00;
}

.path__level_collapsed:hover {
  color: white;
  cursor: pointer;
}

.path__title:hover {
  color: white;
}

.path__editor {
  color: white;
}

.path__editor:hover {
  color: black;
}

.path__editor > * {
  font-size: 2rem;
}

.path__list_group_item.list-group-item.active {
  background-color: #28CCA5;
  border-color: #00b894;
}

.path__list_group_item.to_be_completed:hover {
  background-color: #28CCA5;
  border-color: #00b894;
  cursor: pointer;
}

.path__level_title {
  font-size: 2rem;
}
