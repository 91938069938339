.home__box_col_container {
  border-radius: 5px;
  background-color: #dfe6e9;
  color: #2d3436;
}

.home__box_col_container:hover {
  background-color: #FC8F00;
  color: white;
  cursor: pointer;
}
