.login__not_a_member > a {
  text-decoration: none;
  color: #FC8F00;
}

.login__not_a_member > a:hover {
  text-decoration: underline;
  color: #FC8F00;
  cursor: pointer;
}
